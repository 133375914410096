:root {
    --xxs-spacing: 2px;
    --xs-spacing: 4px;
    --s-spacing: 8px;
    --m-spacing: 16px;
    --l-spacing: 32px;
    --xl-spacing: 64px;

    --main-color: #4A596D;
    --secondary-color: #AAB7C0;


      --xxs-font: calc(8px + 0.4vw);
      --xs-font: calc(12px + 0.4vw);
      --s-font: calc(14px + 0.4vw);
      --m-font: calc(18px + 0.4vw);
      --l-font: calc(24px + 0.4vw);
      --xl-font: calc(32px + 0.4vw);
      --xxl-font: calc(48px + 0.4vw);
    }
