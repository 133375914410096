@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  position: relative;
  background: #e9e9e9;
  //padding-bottom: 305px;
  min-height: 100vh;
}

.main-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button {
  border-radius: 4px;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.flex-31 {
  flex: 0 0 31.333333333%;
  max-width: 31.333333333%;
}

.flex-33 {
  flex: 0 0 33.333333333%;
  max-width: 33.333333333%;
}

.xxs-row {
  display: flex;
  gap: var(--xxs-spacing);
}

.xs-row {
  display: flex;
  gap: var(--xs-spacing);
}

.s-row {
  display: flex;
  gap: var(--s-spacing);
}

.m-row {
  display: flex;
  gap: var(--m-spacing);
}

.l-row {
  display: flex;
  gap: var(--l-spacing);
}

.xl-row {
  display: flex;
  gap: var(--xl-spacing);
}

.xxs-col {
  display: flex;
  flex-direction: column;
  gap: var(--xxs-spacing);
}

.xs-col {
  display: flex;
  flex-direction: column;
  gap: var(--xs-spacing);
}

.s-col {
  display: flex;
  flex-direction: column;
  gap: var(--s-spacing);
}

.m-col {
  display: flex;
  flex-direction: column;
  gap: var(--m-spacing);
}

.l-col {
  display: flex;
  flex-direction: column;
  gap: var(--l-spacing);
}

.xl-col {
  display: flex;
  flex-direction: column;
  gap: var(--xl-spacing);
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: start;
}

.centered {
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}