.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #264027;
    min-height: 180px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        color: #fff;
        margin: 15px 0 10px 0;
        font-size: 1rem;
        font-weight: 300;
    }


    .social-icon,
    .menu {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        flex-wrap: wrap;

        .social-icon-item,
        .menu-item {
            list-style: none;
        }

        .social-icon-link {
            font-size: 2rem;
            color: #fff;
            margin: 0 10px;
            display: inline-block;
            transition: 0.5s;
        }

        .social-icon-link:hover {
            transform: translateY(-10px);
        }

        .menu-link {
            font-size: 1.2rem;
            color: #fff;
            margin: 0 10px;
            display: inline-block;
            transition: 0.5s;
            text-decoration: none;
            opacity: 0.75;
            font-weight: 300;
        }

        .menu-link:hover {
            opacity: 1;
        }
    }



    .wave {
        position: absolute;
        top: -100px;
        left: 0;
        width: 100%;
        height: 100px;
        background: url("../../images/footer/wave.svg");
        background-size: 1000px 220px;

        &#wave1 {
            z-index: 1000;
            opacity: 1;
            bottom: 0;
            animation: animateWaves 8s linear infinite;
        }

        &#wave2 {
            z-index: 999;
            opacity: 0.5;
            bottom: 10px;
            animation: animate 8s linear infinite !important;
        }

        &#wave3 {
            z-index: 1000;
            opacity: 0.2;
            bottom: 15px;
            animation: animateWaves 6s linear infinite;
        }

        &#wave4 {
            z-index: 999;
            opacity: 0.7;
            bottom: 20px;
            animation: animate 6s linear infinite;
        }

        @keyframes animateWaves {
            0% {
                background-position-x: 1000px;
            }

            100% {
                background-positon-x: 0px;
            }
        }

        @keyframes animate {
            0% {
                background-position-x: -1000px;
            }

            100% {
                background-positon-x: 0px;
            }
        }
    }


}