  //header
  
  .header-nav {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  
    .header-nav-btn {
      font-size: 20px;
      display: flex;
      border-bottom: 3px solid transparent;
      padding: 0 var(--xs-spacing);
      font-weight: bold;
      color: var(--main-color);
      text-decoration: none;
      transition: border-bottom 0.1s ease-in-out;
      justify-content: center;
      /* Add transitions here */
  
      &:hover {
        border-bottom: 3px solid var(--main-color);
      }

      &.active{
        border-bottom: 3px solid var(--main-color);
      }
    }

    .change-toggle{
      position: absolute;
      right: 32px;
    }