.small-news-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--s-spacing);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    transform: scale(1);
    transition: transform 0.1s ease-in-out;
    text-decoration: none;
    color: inherit;

    &:hover {
        cursor: pointer;
        color: #5e5e5e;
    }

    &:first-child {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;

        .small-news-header {
            font-size: var(--xl-font) !important;
        }

        .small-news-desc {
            font-size: var(--m-font) !important;
        }

    }

    &:nth-child(2) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
    }

    &:nth-child(3) {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
    }

    .news-breaking-wrapper {
        display: none;
        position: absolute;
        left: -20px;
        top: 0;
        z-index: 2;
        transform: rotate(-45deg);
        border-radius: 30px;
        padding: 2px 10px;
        background-color: red;
        font-weight: bold;

        &.active {
            display: flex;
        }
    }

    .small-news-image-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        border-radius: 8px 8px 0 0;
    }

    .image-offset {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: -10% 0 -15%;


    }

    .small-news-img {
        height: auto;
        width: 100%;
    }

    .small-news-content {
        padding: var(--m-spacing);
        display: flex;
        align-items: center;

        .small-news-header {
            font-size: var(--l-font);
            margin: 0;
            -webkit-line-clamp: 2;
        }
    }



}