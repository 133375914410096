.h2-title {
    font-size: var(--xl-font);
    font-weight: bold;
    text-align: center;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
    position: relative;
}

.about-me-home-wrapper {
    // Add wrapper box styles
    border: 2px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: fit-content;
    margin: 0 auto;
}

.about-me-home-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .about-me-home-image {
        object-fit: cover;
        border-radius: 50%;
        aspect-ratio: 1/1;
        max-height: 250px;
    }
}

.about-me-home-text {
    font-size: var(--m-font);
    color: rgb(65 42 42 / 80%);
    font-weight: 500;
    max-width: 400px;
}

.tech-stack {
    font-size: var(--xl-font);
}

.techStackIcons {
    display: inline-block;
    font-size: var(--xl-font);
    color: #333;
}

.home-show-reel-wrapper {
    &:hover {
        .home-show-reel:not(:hover) {
            filter: blur(4px);
        }
    }

    .home-show-reel {
        display: flex;
        position: relative;
        flex: 0 0 24%;
        min-width: 200px;
        aspect-ratio: 1/2;
        justify-content: center;
        align-items: center;
        transform: scale(1);
        filter: blur(0);
        transition: filter 0.2s ease-in-out, transform 0.2s ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.02);
        }

        .home-show-reel-image {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            object-fit: contain;
            overflow: hidden;
            border-radius: 16px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 16px;
            }
        }

        .home-show-reel-text {
            position: relative;
            z-index: 1;
            font-size: var(--xl-font);
            color: white;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            text-align: center;
        }
    }
}