.carousel {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    display: flex;
    align-items: center;
    overflow-x: auto;

    .carousel-item {
        flex: 0 0 300px;
        margin: 0 10px;
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: scale(1.05);
        }
    }

    .carousel-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 5px;
    }

    .carousel-caption {
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        color: #333333;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: background-color 0.3s ease-in-out;
    }

    scrollbar-width: thin;
    scrollbar-color: #888888 #ffffff;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555555;
    }

    &::-webkit-scrollbar-track {
        background-color: #ffffff;
        border-radius: 4px;
    }
}
