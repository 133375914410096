.portfolio-segment {
    height: 80vh;
    display: flex;

    h2 {
        font-size: var(--xxl-font);
        font-weight: 600;
        margin-left: 10dvw;
    }
}



